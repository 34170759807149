import React from 'react';
import Button from '../Button';

import './SelectSqueeze.scss';

import SelectSqueezeBG from '../../images/squeeze/select-squeeze.png';

const SelectSqueeze = () => (
  <div className='select-squeeze'>
    <img
      className='squeeze-bg'
      src={SelectSqueezeBG}
      alt='Select squeeze thc beverage enhancer'
    />
    <div className='select-squeeze-content'>
      <h1>Introducing Select Squeeze, a perfect dose 5mg THC Beverage enhancer.</h1>
      <h3>
        Feel effects in as little as 15 minutes. Available in 4 delicious flavors. Take it
        anywhere. Mix it with anything. Feel it fast.
      </h3>
      <Button
        id="selectSqueezeButton"
        color="black"
        isTransparent
        hasUnderline
        hasBorder={false}
        to="/find-us"
      >
        Find a store
      </Button>
    </div>
  </div>
);

export default SelectSqueeze;
