import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import SelectSqueeze from '../components/SelectSqueeze/SelectSqueeze';

const SelectSqueezeLayout = () => (
  <Layout>
    <Seo title="Squeeze Homepage" />
    <SelectSqueeze />
  </Layout>
);

export default SelectSqueezeLayout;